.tournage-item {
    padding: 2em 0;
    &:nth-child(even) {
        background-color: $gray-300;
    }
    .tournage-content {
        display:flex;
        flex-wrap: wrap;
    
        .description {
            flex-basis: 75%;
        }
    }
}
