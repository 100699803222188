#home
{
    .logos {
        padding: 1em;
        background-color: var(--bs-body-bg);
        border-radius: var(--bs-border-radius-lg);
        
        display: flex;
        justify-content: space-around;

        // bootstrap scss xl
        @include media-breakpoint-up(xl)
        {
            border-radius: 0;
            border-top-left-radius: var(--bs-border-radius-lg);
            position: absolute;
            right: 0;
            bottom: 0;
            border-bottom: none;
            justify-content: flex-end;

            &:before {
                content: '';
                position: absolute;
                bottom: -4px;
                left: -16px;
                width: 20px;
                height: 20px;
                background-color: var(--bs-primary);
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                border: solid 4px var(--bs-body-bg);
                border-top: none;
                border-left: none;
                border-bottom-right-radius: 14px;
            }
        }


        a {
            display:block;
            margin-left: 1em;
            backface-visibility: hidden;
        }
    }

    #presentation {
        position: relative;

        .location {
            width: 460px;
            margin:auto;
            margin-top: 1em;
            img {
                border: none;
                box-shadow: none;
                transform: none;
                opacity: 1;
                width: 100%;
            }
            
        }
    }

    #social {
        padding: 15px 0;
        font-size: 1.6em;
        line-height: 1.1em;
        .text-right {
            text-align: right;
        }

        a {
            color: white;
            transition: color 0.3s ease-in-out;

            .fab {
                font-size: 2em;
            }

            &:hover {

                color: #1877F2;
            }
        }

        .social-icons {
            padding-left: 1em;
        }

    }
}