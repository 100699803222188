#smart-header {
    display: flex;
    flex-direction: column;
    padding:0;

    // dark gradient from top
    &:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, transparent 25%);
    }

    @include media-breakpoint-up(xl) {
        height: 100vh;
    }

    .photo {
        position: relative;
        overflow: hidden;
        display:flex;
        height: 60vh;

        @include media-breakpoint-up(xl) {
            height: auto;
        }

        &:before {
            background-color: #ffffff9a;
            content: " ";
            // rounded botton ellipse clip path
            clip-path: ellipse(62% 37% at 50% 50%);
            transform: translateY(50%);
            bottom: -28px;
            left:0;
            width:100%;
            height:300px;
            position:absolute;
            z-index: 1;
        }

        &:after {
            background-color: var(--bs-body-bg);
            content: " ";
            // rounded botton ellipse clip path
            clip-path: ellipse(55% 40% at 50% 50%);  
            transform: translateY(50%);
            bottom:-45px;
            left:0;
            width:100%;
            height:300px;
            position:absolute;
            z-index:1;
        }

        img {
            width: 100%;
            height: 100vh;
            object-fit: cover;
            object-position: bottom;

        }

        & > div {
            width: 100%;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            z-index: 2;

            .content-widget {
                justify-content: center;
                text-align: center;

                input {
                    width: 800px;
                }
    
                input, ::placeholder {
                    text-align: center; 
                    mix-blend-mode:inherit;
                    color:rgb(255, 255, 255);
                    text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
                }
            }

            color:rgb(255, 255, 255);
            

            h1 {
                &:before {
                    display: none;
                }
                &::after{
                    display: none;
                }

                text-align: center;
                
            }

            h1, h1 > div {
                text-align: center;
                margin: 0 auto;
                font-size: 1.8rem;
                text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
                font-family: "Anton SC", sans-serif;

                @include media-breakpoint-up(lg) {
                    font-size: 5rem;
                }
            }

            .subtitle {
                text-align: center;
                font-size: 1.4rem;
                margin: 0 auto;
                margin-top: 0;
                text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);

                @include media-breakpoint-up(lg) {
                    font-size: 3rem;
                    margin-top: 0.5em;
                }
            }
        }
    }

    .content-widget, .content-image-widget {
        display: flex;
        width: 100%;

        .btn {
            z-index: 10;
            margin: 0 1em;
            margin-top: 100px;
        }
    }

    .container {
        flex-grow: 1;
    }
}