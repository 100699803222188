#echauffement {
    .logo {
        margin-left:auto;
        @include media-breakpoint-up(lg)
        {
            max-width: 40%;
            margin-top: -20px;
        }

        img {
            transform: rotate(0deg);
        }
        
    }
}

#complexe-sportif {
    .terrain, .terrain input {
        text-align:center;
        font-weight: bold;

        img {
            margin-bottom: 1em;
        }
    }

    .col {

        &:nth-child(2) {
            .terrain {
                img {
                    transform: rotate(-2deg);
                }
            }
        }

        &:nth-child(3) {
            .terrain {
                img {
                    transform: rotate(-1deg);
                }
            }
        }

        &:nth-child(4) {
            .terrain {
                img {
                    transform: rotate(2deg);
                }
            }
        }
    }

    .primary {
        .terrain, .terrain input {
            color: white;
        }
    }
}