#page-editor {
    .wysiwyg-toolbar {
        position: absolute;
    }
}

.content-save-button {
    position: fixed;
    left: 15px;
    bottom: 55px;
}

.wysiwyg-air {
    .note-editor {
        &.note-frame {
            border: none;

            .note-editing-area {
                .note-editable {
                    padding: 0;
                }
            }
        }

        .note-toolbar-wrapper {
            position: fixed;
            top: 4px;
            z-index: 1050;
            border: solid 1px $card-border-color;
        }

        .note-statusbar {
            display: none;
        }

        .note-editing-area {
            img.note-float-left {
                margin: 0;
            }
        }
    }

}

.page-content-widget-simple-text {
    border: none;
    background: none;
    padding: 0;

    &:focus {
        border: dotted 1px #88888873;
        outline: none;
    }
}