.smart-icons {
    padding: 50px 0;
    display:flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    position:relative;
    z-index: 2;

    .smart-icon {
        margin: 0 15px;
        margin-bottom: 20px;
        flex-basis: 25%;

        @include media-breakpoint-up(xl) {
            margin: 0 40px;
            flex-basis: auto;
        }
        .icon-content {
            width: 160px;
            height: 160px;
            background-color:#eee;
            background-image: linear-gradient(45deg, $secondary, lighten($secondary, 20%));
            border: solid 4px white;
            overflow: hidden;
            display:flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin:auto;
           
            box-shadow: $box-shadow;

            &.green { 
                background-image: linear-gradient(45deg, #479e5e, lighten(#479e5e, 20%));
            }

            &.blue { 
                background-image: linear-gradient(45deg, #1985a1, lighten(#1985a1, 20%));
            }

            &.red { 
                background-image: linear-gradient(45deg, #a11919, lighten(#a11919, 20%));
            }

            &.brown {
                background-image: linear-gradient(45deg, #8d5b4c, lighten(#8d5b4c, 20%));
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 5s , filter 0.4s;
                backface-visibility: hidden;
            }
    
            @include media-breakpoint-up(xl) {
                width: 170px;
                height: 170px;
            }

            i {
                display: inline-block;
                font-size: 3em;
                color: #fff;
            }

            &.sm {
                width: 100px;
                height: 100px;

                @include media-breakpoint-up(xl) {
                    width: 120px;
                    height: 120px;
                }
            }
        }

        .icon-label {
            text-align: center;
            margin-top: 16px;
            font-size: 1.1em;
            transition: letter-spacing 0.3s;
            line-height: 1em;

            @include media-breakpoint-up(lg) {
                font-size: 1.2em;
            }
        }

        &:is(button) {
            padding: 0;
            background: none;
            border: none;

            .icon-content {
                img {
                    filter: sepia(50%);
                }
            }

            &:hover {
                cursor: pointer;
                .icon-label {
                    letter-spacing: 2px;
                }

                .icon-content {
                    img {
                        transform: scale(1.5);
                        filter: sepia(0%);
                    }
                }
            }
        }
        
    }

    > :nth-child(1) {
        .icon-content {
            transform: rotate(1deg);
        }
    }

    > :nth-child(2) {
        .icon-content {
            transform: rotate(-1deg);
        }
    }

    > :nth-child(3) {
        .icon-content {
            transform: rotate(2deg);
        }
    }

    > :nth-child(4) {
        .icon-content {
            transform: rotate(-1deg);
        }
    }

    .dropdown-menu {
        min-width: 100%;
        padding:0;
        .dropdown-item {
            text-align: center;
        }

        animation-name: slideIn;
        animation-duration: 0.3s;
        animation-fill-mode: both;

        .dropdown-item {
            padding: 0;
            a {
                display: block;
                width: 100%;
                color: $body-color;
                transition: background-color 0.3s, color 0.3s;
                padding: 0.5em 1em;

                &:hover {
                    background-color: $primary;
                    color: white;
                }
            }
        }
    }
}

#smart-header .smart-icons {
    margin-top: -150px;
}