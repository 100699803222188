@import "listTree.scss";
$height: add($input-height, 2px);
$primary-color: $primary;
$border-color: $input-border-color;
$border-radius: $input-border-radius;
@import "node_modules/slim-select/src/slim-select/slimselect.scss";
@import "node_modules/dropzone/src/dropzone.scss";

@import "./file-manager.scss";
@import './common.scss';

.fade {
    &.in {
        opacity: 1;
    }
}

.whitespace-pre {
    white-space: pre-wrap;
}

.bg-gray {
    background-color: $gray-200;
}

.ss-main {
    padding: $form-select-padding-y $form-select-padding-x;
    min-width: 136px;

    .ss-values {
        .ss-single {
            line-height: $line-height-base;
        }

        .ss-value {
            .ss-value-text {
                line-height: $line-height-base;
            }

            .ss-value-delete {
                width: auto;
                height: auto;
            }
        }
    }
}

.form-group {
    @extend .mb-3;
}

.card {
    .table {
        margin-bottom: 0;
    }

    &.card-sm {
        .card-header {
            padding: 0.25rem 0.5rem;
        }

        .card-body {
            padding: 0.5rem;
        }
    }

    &.card-smart-border {
        border-top-width: 3px;

        &.card-primary {
            border-top-color: $primary;
        }

        &.card-secondary {
            border-top-color: $secondary;
        }

        &.card-danger {
            border-top-color: $danger;
        }

        &.card-warning {
            border-top-color: $warning;
        }

        &.card-info {
            border-top-color: $info;
        }

        &.card-success {
            border-top-color: $success;
        }
    }
}

tr {
    .custom-control {
        label {
            display: inline;
        }
    }
}

[draggable="true"] {
    * {
        pointer-events: none;
    }

    button,
    a,
    input,
    textarea,
    [draggable="true"] {
        pointer-events: auto;
    }
}

.sort-item {
    cursor: grab;
}

/*
 * Buttons
 */
.btn-xls {
    background-color: #02723b;
    color: #fff;

    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &.active:hover {
        background-color: #004523;
        color: #fff;
    }
}

.btn-label {
    background: rgba(43, 61, 81, 0.1);
    display: inline-block;
    padding: $input-btn-padding-y 15px;
    border-radius: $btn-border-radius 0 0 $btn-border-radius;
    margin: -10px -13px;
    margin-right: 5px;
}

.btn-icon {
    padding-top: 1em;

    i {
        font-size: 2rem;
        display: block;
        margin: 2px 0;
    }
}

.btn-link-inline {
    margin: 0;
    padding: 0;
}

.navbar {
    .btn {
        padding: 0.5rem 1rem;
    }
}

.input-group-sm {
    .btn {
        @extend .btn-sm;
    }
}

/*
* Dialog
*/
.modal-dialog {

    &.modal-dialog-scrollable {

        form,
        .card {
            max-height: 100%;
            overflow: hidden;

            .card-body {
                overflow-y: auto;
                max-height: 75vh;
            }
        }
    }
}

.bootstrap-select .dropdown-menu {
    z-index: 1033;
}

/*
 * Login/Register frame
 */
.registration-frame {
    max-width: 700px;
    margin: auto;
    margin-top: 20px;
}

/*
* Content editable placeholder
*/
[contenteditable=true]:empty:before {
    content: attr(placeholder);
    display: block;
    /* For Firefox */
    color: $gray-500;
}

.form-control[contenteditable=true] {
    height: auto !important;
}

.wysiwyg-air .note-editor .note-toolbar-wrapper {
    border: none !important;
}

.note-editor .note-dropzone,
.note-editor.note-frame .note-editing-area .note-editable,
.wysiwyg-air .note-editor.note-frame {
    background: none !important;
}

.note-toolbar {
    z-index: 1031 !important;
}

/**
 Alert blockquote
*/
.alert {

    blockquote {
        border-left: 10px solid $gray-200;
        margin: 1.5em 10px;
        padding: 0.5em 10px;
        quotes: "\201C" "\201D" "\2018" "\2019";

        &:before {
            color: $gray-200;
            content: open-quote;
            font-size: 4em;
            line-height: 0.1em;
            margin-right: 0.25em;
            vertical-align: -0.4em;
        }
    }

    &.alert-danger {

        blockquote {
            border-color: $danger;

            &:before {
                color: $danger;
            }
        }
    }
}

.modal-full {
    max-width: 90vw;
    margin-left: 5vw;
}

.ui-draggable-dragging {
    opacity: 0.5;
    z-index: 100;
}

/**
    Notification
*/
.notification {
    position: fixed;
    top: 80vh;
    right: 15px;

    .toast-header {
        img {
            height: 20px;
        }
    }
}

#notifications {
    .notification-list {
        .notification-icon {
            max-width: 80px;
        }
    }
}

#notification-list {
    .notification-list {
        .notification-icon {
            max-width: 120px;
        }
    }
}

.btn-align {
    padding: 6px 12px;
    line-height: 1.42857143;
    vertical-align: middle;
}

/* Sub dropdown */
.dropdown-submenu {
    position: relative;

    >a:after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.1em;
        content: "";
        border-top: 0.3em solid transparent;
        border-right: 0.3em solid transparent;
        border-bottom: 0.3em solid transparent;
        border-left: 0.3em solid;
    }

    >.dropdown-menu {
        top: 0;
        left: 100%;
        margin-top: 0px;
        margin-left: 0px;
    }
}

/**
 * Summernote emoji
 * https://github.com/tylerecouture/summernote-ext-emoji-ajax/blob/master/summernote-ext-emoji-ajax.css
 */
.note-ext-emoji-list {
    width: 180px;
    height: 200px;
    overflow: auto;
    padding-left: 5px;
}

.note-ext-emoji-list button {
    margin-top: 5px;
    margin-right: 5px;
    padding: 0px;
}

.note-ext-emoji-list .note-emoji-btn img {
    width: 20px;
}

img.emoji-img-inline {
    display: inline-block;
    margin-top: -0.3em;
    height: 1.2em;
    width: auto;
}

.note-ext-emoji-search {
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.summernote-textarea {
    display: none;
}

.color-icon {
    margin-right: 6px;
    width: 20px;
    height: 20px;
    float: left;
}

.bootstrap-select {

    .text,
    .filter-option-inner-inner {
        line-height: 18px;
    }
}

/********************************
FILE
*********************************/
//@import "~cropperjs/dist/cropper.css";

.upload-zone {
    position: relative;
    cursor: pointer;

    &.disabled {
        opacity: 0.4;
    }



    .fileinput-button {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        input {
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
        }

        .zone-label {
            border: dashed 3px rgba($black, .125);
            text-align: center;
            display: block;
            padding: 8px 12px;
            font-size: 1.2rem;
            color: rgba($black, .512);
            font-weight: bold;

        }

        &:hover {

            .zone-label {
                border-color: $primary;
            }
        }
    }
}



.fileinput-button {
    position: relative;

    input {
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        left: 0;
    }
}

.img-preview-background {
    background-color: #dddddd;
    background-image: linear-gradient(45deg, #aaaaaa 25%, transparent 25%), linear-gradient(-45deg, #aaaaaa 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #aaaaaa 75%), linear-gradient(-45deg, transparent 75%, #aaaaaa 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    width: 100%;
    padding: 15px;
    text-align: center;
}

.fileupload-control {

    &:not([multiple]) {
        max-width: 400px;
    }


    .fileupload-control-container {
        text-align: center;

        .preview,
        .preview-content {
            img {
                max-width: 100%;
                height: auto;
                pointer-events: none;
            }
        }

        .btn-remove {
            position: absolute;
            right: 6px;
            top: 6px;
        }

        &.card-columns {
            @include media-breakpoint-only(lg) {
                column-count: 4;
            }

            @include media-breakpoint-only(xl) {
                column-count: 5;
            }
        }
    }

    /*border: solid $gray-200 1px;
  padding: 8px;*/
}

.download-file {
    display: inline-block;
    background-color: $light;
    padding: 4px;
    min-width: 180px;

    &:after {
        display: block;
        content: attr(data-size);
        color: $secondary;
        font-size: 0.8em;
    }

    &:hover {
        text-decoration: none;
    }
}

.fileinput-preview {


    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    position: relative;

    .fileinput-preview-item {
        flex: 1 0 33%;
        position: relative;

        img {
            display: block;
            max-width: 100%;
        }
    }

    .fileupload-item-delete {
        position: absolute;
        top: 0px;
        right: 7px;
        background-color: rgba(255, 255, 255, 0.8);
    }
}

div>.list-tree {
    overflow-y: auto;
    max-height: 68vh;
}

/* datalist */
.data-list-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    ul.pagination {
        margin: 0;
    }
}

/** Autocomplete list */
.autocomplete-list,
.search-bar-result {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1032;
    background-color: $light;
    border: solid 1px $gray-300;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;

    li,
    .search-bar-result-item {
        list-style: none;
        border-bottom: solid 1px $gray-300;
        padding: 12px 15px;

        &:last-child {
            border: none;
        }

        &:hover {
            cursor: pointer;
            background-color: $gray-400;
        }
    }
}

#search-bar {
    position: relative;

    .search-bar-result-item {
        display: flex;

        .image {
            width: 140px;
            margin-right: 8px;

            img {
                max-width: 100%;
                display: inline-block;
                margin: auto;
            }
        }

        .title {
            font-weight: bold;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

/*****************
 INFO BOX
 *****************/
.info-box {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;

    .info-box-icon {
        padding: 10px 25px;
        font-size: 4em;
        background-color: rgba(0, 0, 0, 0.2);
    }

    .info-box-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 8px;

        .value {
            font-size: 1.7em;
            text-align: center;
        }
    }

    .title {
        text-transform: uppercase;

    }
}

.focus-control {
    background: transparent;
    border: none;

    &:focus {
        @extend .form-control;
    }
}

.form-check-input.custom-control-default:checked~.form-check-label::before {
    border-color: $gray-500;
    background-color: $gray-500;
}

#user-profile,
#my-account {
    .userimage {
        width: 200px;
        margin: 15px auto;
        overflow: hidden;
        border-radius: $border-radius;

        img {
            display: block;
            max-width: 100%;
        }
    }

    .userinfos {
        flex-grow: 1;
        padding: 15px;
    }
}