.tarifs {
    margin: auto;
    background-color: var(--bs-body-bg);
    border-radius: var(--bs-border-radius);
    border: solid 2px var(--bs-secondary);
    box-shadow: $box-shadow;
    .tarif-item {
        padding: 0.3em 1em;
        display:flex;
        justify-content: space-between;
        align-items: center;

        &:nth-child(odd) {
            background-color: #00000015;
        }

        h3{
            font-size: 1.1em;
            font-weight: bold;
            margin: 0;
        }

        .price {
            text-align: right;
            input {
                text-align: right;
            }
        }
    }

    // xl
    @include media-breakpoint-up(xl) {
        width: 60%;
    }
}