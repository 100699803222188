#event-list {
    position:relative;
    z-index: 0;
    .col {
        margin-top: 2.5em;
    }
    .event {
        position: relative;
        z-index:0;
        border: solid 3px white;

        h2 {
            font-size: 1.1em;
            color: white;
        }

        color: white;

        img {
            height: 268px;
            object-fit: cover;
            filter: brightness(1);
            transition: filter 0.3s ease-in-out;
        }

        .card-img-overlay {
            height: 266px;
            overflow: hidden;
            opacity: 0;
            transition:opacity 0.3s ease-in-out;
        }

        .event-date {
            color: white;
            background-color: $secondary;
            padding:5px 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .event-date-month-year {
                display: flex;
                flex-direction: column;
                margin-left: 1em;

                .event-date-month {
                    font-size: 1.2em;
                }

                .event-date-year {
                    font-size: 0.9em;
                }
            }
    
            .event-date-day {
                font-size: 2.6em;
            }
    
        }

        &:hover {
            img {
                filter: brightness(0.2);
            }

            .card-img-overlay {
                opacity: 1;
            }
        }

        .event-name {
            color: white;
            background-color:#1111113a;
            padding: 0.5em;
            margin-left: 1em;
            font-size: 1.1em;
            flex-grow: 1;
            text-align: center;
            line-height: 1.1em;
        }
    }
}