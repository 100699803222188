#facebook-posts {
    a {
        color: var(--bs-body-color);
    }
    .facebook-posts-container {
        border: solid 4px white;
        box-shadow: var(--bs-box-shadow);
        padding: 1em 48px;

        .splide__arrow {
            background-color: $secondary;
        }

        .splide__arrow.splide__arrow--prev {
            left: -2em;
        }

        .splide__arrow.splide__arrow--next {
            right: -2em;
        }
    }

    .item {
        .item-img {
            height: 290px;
            width: 100%;
            object-fit: contain;
            background-image: linear-gradient(45deg, #adadad, lighten(#adadad, 20%));
        }

        .item-body {
            padding: 0.5em;

            .item-date {
                text-align: right;
                color: $secondary;
                font-size: .8em;
                margin-bottom: .8em;
            }
        }
    } 
}

.facebook-post-view {
    .carousel-item {
        img {
            height: 60vh;
            width: 100%;
            object-fit: contain;
            background-image: linear-gradient(45deg, #adadad, lighten(#adadad, 20%));
        }
        
    }

    .item-date {
        font-size: .9em;
        margin-bottom: 0.5em;
        text-align: right;
        color: $secondary;
    }
}