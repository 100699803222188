#tigrisFileSelect {
    z-index: 1051;
}

#file-manager {
    .upload-zone {
        border-bottom: solid 1px rgba($black, .125);

        .fileinput-button {
            justify-content: center;
        }

        .dropzone {
            border: dashed 2px $gray-400;
            min-height: 120px;
            color: $gray-600;
            font-size: 1.1em;
        }
    }


    >.data-list>.card>.card-body {
        padding: 0;
    }

    .file-list {
        position: relative;
        height: 50vh;
        overflow-y: auto;
        padding: 15px;

        .card-columns {
            @include media-breakpoint-only(lg) {
                column-count: 4;
            }

            @include media-breakpoint-only(xl) {
                column-count: 5;
            }
        }

        .folder-path {
            border-bottom: solid rgba($black, .125) 1px;
            background-color: $gray-100;
            padding: 4px;
            min-height: 22px;
            margin-left: -30px;
        }

        .file-items {
            padding-bottom: 15px;

            .file-item {
                height: 190px;
                width: 25%;
                float: left;
                text-align: center;
                position: relative;
                padding: 2px;

                .card {
                    height: 100%;
                }

                &.current {
                    .card {
                        background-color: lighten($primary, 20%);
                    }

                }

                .file-preview {
                    display: inline-block;
                    max-height: 90px;
                    max-width: 90px;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                .file-icon {
                    font-size: 4rem;
                }

                audio,
                video {
                    width: 240px !important;
                }

                .file-label {
                    white-space: nowrap;
                    overflow: hidden;
                    position: relative;
                    padding-left: 4px;
                }

                .file-item-edit {
                    position: absolute;
                    left: -1px;
                    top: -1px;
                }
            }
        }
    }



    .folder-tools {
        position: absolute;
        top: 7px;
        left: 8px;
    }

    .folder-list {
        border-right: solid 1px rgba($black, .125);
        padding-top: 15px;
        padding-bottom: 15px;
        height: 100%;
        background-color: var(--bs-tertiary-bg);

        li {
            span {
                display: block;
            }
        }
    }
}

.tigris-file-preview {
    .file-infos {
        text-align: right;
        font-size: 0.8em;
    }

    .image-preview {
        background-color: var(--bs-body-bg);
        background-image: linear-gradient(45deg, var(--bs-border-color) 25%, transparent 25%, transparent 75%, var(--bs-border-color) 75%), linear-gradient(45deg, var(--bs-border-color) 25%, transparent 25%, transparent 75%, var(--bs-border-color) 75%);
        background-size: 16px 16px;
        background-position: 0 0, 8px 8px;

        width: 100%;
        display: flex;
        justify-content: center;
        padding: 8px;
        border: solid var(--bs-light-border-subtle) 1px;

        img {
            display: inline-block;
            max-height: 30vh;
            max-width: 90%;
        }
    }

    margin-bottom: 0.5em;
}