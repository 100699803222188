@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap');
@import url('./../../node_modules/aos/dist/aos.css');
@import url('https://fonts.googleapis.com/css2?family=Anton+SC&display=swap');
/* =====================
 BS VARS
========================*/
$enable-rounded: false;
$dark: #383030;
$primary: #c4464b; /* 9e2a2b OR c4464b */
$secondary: #e09f3e;
$info: #b36269;
$body-color: #333;
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);
$header-height: 125px;
$link-decoration: none;
$min-contrast-ratio: 2;
$body-bg: #f8f7f0;
$font-family-base: 'Barlow Condensed', sans-serif;
$font-size-base: 1.2rem;
$box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
$breadcrumb-margin-bottom: 0;
$enable-shadows: true;

$card-border-width: 3px;
$card-border-color: white;
$card-box-shadow: $box-shadow;
$border-radius: 0;

@import 'common.scss';
@import './../../vendor/oviglo/tigris-base-bundle/assets/scss/common.scss';

:root {
    --ss-main-height: 42px;
}

.content-widget {
    input {
        color: $body-color;
        font-size: inherit;
        font-weight: inherit;
        text-transform: inherit;
    }

    .btn {
        z-index: 1;
    }
}

.btn-save {
    z-index: 100;
}

.chosen-focus-input{
    display: none;
}

.content-save-button{
    z-index: 5;
    top: 23px;
    right: 58px;
    left: auto;
    bottom: auto;
}

.btn-facebook{
    background-color: #4267b2;
    color: white;

    &:hover
    {
        background-color: darken(#4267b2, 5%);
        color: white;
    }
}

.btn
{
    box-shadow: inset 0 0 0 rgba(0, 0, 0, .3);
    transition: all 0.3s;

    &:hover
    {
        box-shadow: inset 0 -3px 0 rgba(0, 0, 0, .3);
    }
}

.fc-event-dot,
.fc-event
{
    background-color: $primary;
    border-color: $primary;

    &.senones-en-fete
    {
        background-color: #47d59a;
        border-color: #47d59a;
    }
}

/* =========================
 ANIMATIONS
 =========================== */
@keyframes slideIn
{
    0%
    {
        margin-top: 1rem;
        opacity: 0;
    }

    100%
    {
        margin-top: 0;
        opacity: 1;
    }
}

@keyframes scale
{
    0%
    {
        transform: scale(1);
        opacity: 1;
    }

    100%
    {
        transform: scale(1.4);
        opacity: 0;
    }
}

/* =====================
 GLOBAL
========================*/
html
{
    scroll-behavior: smooth;

    &::-webkit-scrollbar-track
    {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: lighten($dark, 40%);
    }

    &::-webkit-scrollbar
    {
        width: 12px;
        background-color: $dark;
    }

    &::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #555;
    }
}

body
{
    &.load
    {

        &:after
        {

            content: ' ';
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: $primary;
            display: block;
            margin: auto;
            position: absolute;
            top: calc(50% - 20px);
            left: calc(50% - 20px);
            animation: scale 1.5s infinite;
        }

        &:before
        {
            content: "Ville de Senones";
            display: block;
            position: absolute;
            font-size: 1.6rem;
            top: calc(50% + 60px);
            left: 0;
            right: 0;
            text-align: center;
            color: $gray-600;
        }

        #app
        {
            opacity: 0;
        }
    }

    &:not(.has-smart-header)
    {
        padding-top: 90px;
    }
}

section {
    padding: 40px 0;

    header {
        margin-bottom: 2.2em;
        h2 {
            font-family: "Anton SC", sans-serif;
            font-size: 1.6em;
        }
    }

    &.primary {
        background-color: $primary;
        color: white;
    }

    &.secondary {
        background-color: $secondary;
        color: white;
    }

    &.gray {
        background-color: #e0e2db;
    }

    .content {
        img {
            max-width: 100%;
        }
    }
    
    .image {
        display:flex;
        flex-direction: column;
        img {
            max-width: 100%;
            border-radius: var(--bs-border-radius);
            box-shadow: $box-shadow;
            border: solid white 3px;
            transform: rotate(2deg);
            backface-visibility: hidden;
        }
        padding: 0.5em;
    }

}

#app
{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    transition: opacity 0.4s;

    .body-container
    {
        flex-grow: 1;
        @include media-breakpoint-up(sm)
        {
        }

        @include media-breakpoint-up(lg)
        {
        }
    }
}

address
{
    margin: 0;
}

.bootstrap-select > .dropdown-toggle
{
    padding: 0.64rem 0.75rem;
}

.blueimp-gallery
{
    background: rgba(0, 0, 0, 0.7) !important;

    .close
    {
        font-size: 1.5em;
    }
}

/* Arrow Breadcrumb */
.breadcrumb{
    padding: 0;
    background-color: transparent;
    z-index: 1;
    position: relative;

    .breadcrumb-item + .breadcrumb-item{
        &::before{
            content: '';
            padding: 0;
            content: "";
            background-color: $secondary;
            width: 12px;
            height: 100%;
            margin-left: -23px;
        }
    }

    .breadcrumb-item
    {
        display: flex;
        vertical-align: top;

        a
        {
            display: inline-block;
            vertical-align: top;
            height: 40px;
            padding: 0 10px 0 10px;
            line-height: 40px;
            position: relative;
            color: #fff;
            text-decoration: none;
            background-color: $secondary;
            border: 1px solid $secondary;
            text-align: center;

            &:before
            {
                position: absolute;
                top: -1px;
                width: 0;
                height: 0;
                content: '';
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
                right: -12px;
                border-left-color: $secondary;
                border-left-style: solid;
                border-left-width: 11px;
            }

            &:focus
            {
                background-color: lighten($secondary, 10%);
                border: 1px solid lighten($secondary, 10%);

                &:before
                {
                    border-left-color: lighten($secondary, 10%);
                }
            }
        }

        span
        {
            display: inline-block;
            vertical-align: top;
        }

        &:not(:first-child)
        {
            margin-left: 15px;
        }

        &:first-child
        {
            a
            {
                background-color: darken($secondary, 10%);
                border: 1px solid darken($secondary, 10%);

                &:before
                {
                    border-left-color: darken($secondary, 10%);
                }
            }
        }
    }

    .breadcrumb-item.active
    {
        &:before
        {
            display: none;
        }

        a
        {
            background-color: transparent;
            color: $body-secondary-color;
            padding: 0 10px 0 8px;
            line-height: 40px;
            padding-right: 10px;
            border: none;

            &:before
            {
                display: none;
            }
        }
    }

    li
    {
        a
        {
            &:hover
            {
                background-color: $secondary;
                border: 1px solid $secondary;

                &:before
                {
                    border-left-color: $secondary;
                }
            }
        }
    }

    li.active
    {
        &:after
        {
            border-left-color: darken($secondary, 15%);
        }

        &:before
        {
            border-left-color: darken($secondary, 15%);
        }
    }
}

/*=======================
 PAGE CONTENT
=========================*/
.page-content
{
    flex-grow: 1;

    h1
    {
        font-size: 1.8em;

        font-family: "Anton SC", sans-serif;

        position: relative;
        display: inline-flex;
        width: 100%;
        align-items: center;
        margin: 0.6em 0;

        &:after
        {
            content: " ";
            height: 4px;
            border: 1px $gray-400 solid;
            border-left: none;
            border-right: none;
            display: block;
            flex-grow: 1;
            margin-left: 20px;
            margin-top: 10px;
        }
    }

    .page-text
    {
        padding: 15px;
        background-color: white;
    }

    article
    {
        .article-preview
        {
            width: 100%;
            height: 200px;
            background-size: cover;
            background-position: center;

            @include media-breakpoint-up(md)
            {
                height: 260px;
            }

            @include media-breakpoint-up(lg)
            {
                height: 350px;
            }
        }

        .article-content
        {
            padding: 15px;

            img
            {
                display: inline-block;
                max-width: 100%;
            }

            .article-infos
            {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
            }
        }
    }

    blockquote
    {
        display: block;
        background: #f9f9f9;
        border-left: 10px solid #ccc;
        margin: 1.5em 10px;
        padding: 0.5em 10px;
        quotes: "\201C" "\201D" "\2018" "\2019";

        &:before
        {
            color: #ccc;
            content: open-quote;
            font-size: 4em;
            line-height: 0.1em;
            margin-right: 0.25em;
            vertical-align: -0.4em;
        }
    }

    .event-dates
    {
        background-color: $primary;
        color: white;
        padding: 6px 8px;
        margin-bottom: 20px;
    }
}

#left-side
{
    .left-side-container
    {
        position: sticky;
        top: 64px;
    }

    .card
    {
        border: none;

        .card-header
        {
            background-color: $secondary;
            color: white;
        }

        .card-footer
        {
            background-color: transparent;
        }
    }
}

.menu-frame
{
    position: relative;
    margin-bottom: 50px;

    .frame-img
    {
        background-color: $dark;

        img
        {
            display: inline-block;
            width: 100%;
            opacity: 0.6;
            transition: opacity 0.3s, transform 0.3s;
        }

        @include media-breakpoint-up(md)
        {
            height: 250px;
        }

        @include media-breakpoint-up(xl)
        {
            height: 180px;
        }

        overflow: hidden;
    }

    &:hover
    {
        .frame-img
        {
            img
            {
                opacity: 1;
                transform: scale(1.2);
            }
        }
    }

    .menu-frame-footer
    {
        .btn
        {
            font-size: 1.2em;
        }
    }
}

#content-page
{
    padding-top: 50px;
    padding-bottom: 50px;
}

#weather
{
    position: relative;
    color: white;
    background-image: url("../img/Sky_Background.jpg");
    background-size: cover;
    border: none;

    &::before
    {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0.5;
        background-color: black;
        z-index: 0;
    }

    .weather-body
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .j-1
        {
            display: flex;
            flex-direction: column;

            .temp-max
            {
                font-size: 3.2rem;
                text-align: center;
            }
        }

        .j-s
        {
            margin-top: 8px;
            padding-top: 8px;
            border-top: 1px white solid;
            display: flex;
            justify-content: space-around;
            width: 100%;

            .day
            {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    &.card > .card-header
    {
        background: none;
        border: none;
        text-align: center;
        z-index: 1;

        h2
        {
            font-size: 1.6rem;
            padding: 0;
            margin: 0;
        }
    }
}

.news
{
    border-color: $gray-200;

    .card-header
    {
        background: none;

        .card-title
        {
            font-size: 1.2rem;
            text-transform: uppercase;
            margin-bottom: 0;
            position: relative;
            padding-left: 26px;

            &::before
            {
                content: " ";
                position: absolute;
                left: 0;
                top: 4px;
                bottom: 4px;
                width: 18px;
                background-color: $primary;
            }
        }
    }

    .card-footer
    {
        background: none;
        border-top: none;
        padding: 0;

        .btn-block
        {
            padding: .7rem .75rem;
            font-size: 1.1rem;
        }
    }
}

.media
{
    border-bottom: solid $gray-300 4px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    position: relative;

    .media-body
    {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @include media-breakpoint-up(md)
    {
        flex-direction: row;
    }

    img
    {
        width: 100%;
        margin-bottom: 15px;
        display: inline-block;

        @include media-breakpoint-up(md)
        {
            width: 200px;
        }
    }

    h2
    {
        font-size: 1.2rem;
        font-weight: bold;
        color: $primary;

        &:after
        {
            display: none;
        }
    }

    .infos
    {
        border-top: solid $gray-200 1px;
        padding-top: 20px;
        margin-top: 20px;
        font-size: 0.9rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $gray-600;

        span
        {
            display: inline-block;
            margin-right: 6px;
        }
    }

    .preview
    {
        height: 220px;
        max-height: 220px;
        margin: 10px;
        margin-top: 0;

        .content
        {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            width: 220px;
            height: 220px;
            overflow: hidden;

            img
            {
                width: 100%;
                height: 100%;
                max-width: initial;
                max-height: initial;
                margin: 0;
                object-fit: cover;
            }

            .default
            {
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                background-color: $gray-300;

                i
                {
                    font-size: 3em;
                    text-align: center;
                    color: $gray-700;
                }
            }

        }
    }
}

.item-btn-group
{
    min-width: 180px;
}

#decouvrir
{
    position: fixed;
    width: 100%;
    height: 100vh;
    margin-left: 100vw;
    z-index: 2;
    background: $dark;
    color: white;
    padding: 20px;
    top: 0;
    transition: margin-left 0.4s;
    overflow-y: auto;

    @include media-breakpoint-up(md)
    {}

    &::-webkit-scrollbar-track
    {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: lighten($dark, 40%);
    }

    &::-webkit-scrollbar
    {
        width: 12px;
        background-color: $dark;
    }

    &::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #555;
    }

    .close-decouvrir
    {
        color: white;
        font-size: 1.5rem;
        position: absolute;
        background-color: transparent;
        right: 15px;
        top: 15px;
    }

    &.active
    {
        margin-left: 0;
        display: block;
    }

    .gallery
    {
        a
        {
            img
            {
                filter: grayscale(100%);
            }

            &:hover
            {
                img
                {
                    filter: grayscale(0%);
                    ;
                }
            }
        }
    }

    .content
    {
        @include media-breakpoint-up(md)
        {
            overflow-y: auto;
            height: 90vh;
            padding-right: 15px;

            &::-webkit-scrollbar-track
            {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: lighten($dark, 40%);
            }

            &::-webkit-scrollbar
            {
                width: 12px;
                background-color: $dark;
            }

            &::-webkit-scrollbar-thumb
            {
                border-radius: 10px;
                box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                background-color: #555;
            }
        }
    }
}

/*==================
 ELU
==================== */
#elus
{
    .elu
    {
        h2
        {
            margin-top: 15px;
        }

        text-align:center;

        .name
        {
            font-size: 1.3rem;
            font-style: italic;
        }
    }
}

.ss-main
{
    margin-right: 16px;

    .ss-multi-selected
    {
        border-radius: 0;
        min-width: 340px;

        .ss-add
        {
            margin: 15px 9px 7px 9px;
        }
    }
}

#main-info-message
{
    border: $primary solid 1px;
    padding: 6px 12px;
    background: white;

    p
    {
        margin: 0;
    }
}

#home
{
    #main-info-message
    {
        position: absolute;
        background-color: #ffffff9a;
        top: 100px;
        left: 15px;
        right: 15px;
    }
}

.login-frame {
    max-width: 400px;
    margin: 2em auto;
}

.filter-form {
    display:flex;
    justify-content: flex-start;
    align-items: center;

    select {
        max-width: 400px;
    }
}

@import './front/home.scss';
@import './front/smart-header.scss';
@import './front/smart-icons.scss';
@import './front/main-menu.scss';
@import './front/tarifs.scss';
@import './front/tournage.scss';
@import './front/facebook_post.scss';
@import './front/facebook_event.scss';
@import './front/complexe_sportif.scss';
@import './front/footer.scss';