#main-header
{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    margin-top: 20px;
    color: white;
    transition: margin-top 0.2s;
    background-color: $dark;

    &.scrolled
    {
        margin-top: 0;
    }

    @include media-breakpoint-up(lg)
    {
        border-left: none;
        border-right: none;
    }

    .navbar
    {
        text-transform: uppercase;
        padding: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 2;
        position: fixed;
        background-color: $dark;

        @include media-breakpoint-up(lg)
        {
            position: relative;
        }

        .navbar-brand
        {
            color: white;
            margin-left: 15px;
            font-size: 1.6rem;

            @include media-breakpoint-up(lg)
            {
                display: inline-block;
                margin-left: 15px;
                font-size: 1.2rem;
            }
        }

        .btn-link
        {
            color: white;
        }

        .navbar-collapse
        {
            justify-content: space-evenly;
        }

        .navbar-nav
        {
            position: relative;
            background-color: $dark;

            @include media-breakpoint-up(lg)
            {
                background: none;
            }

            .nav-item
            {
                text-align: center;
            }

            .dropdown-item
            {
                padding: 0.5rem 1rem;
            }

            .nav-link
            {
                position: relative;
                transition: color 0.2s, background-color 0.2s;
                padding-left: 15px;
                padding-right: 15px;
                color: white;
                font-size: 1.4em;

                &:hover
                {
                    color: white;
                    background-color: $primary;
                }

                @include media-breakpoint-up(lg)
                {
                    font-size: 0.8em;

                    i
                    {
                        /*display:none;*/
                    }
                }

                @include media-breakpoint-up(xl)
                {
                    font-size: 1.01em;

                    i
                    {
                        display: inline;
                    }
                }
            }

            .active
            {
                & > .nav-link
                {
                    &::before
                    {
                        height: 5px;
                    }
                }
            }
        }

        /* hover dropdown */
        @include media-breakpoint-up(lg)
        {

            .dropdown:hover > .dropdown-menu,
            .dropdown-submenu:hover > .dropdown-menu
            {
                display: block;
            }

            .dropdown > .dropdown-toggle:active,
            .dropdown-submenu > .dropdown-toggle:active
            {
                pointer-events: none;
            }
        }

        .dropdown-menu
        {
            background-color: lighten($dark, 5%);
            margin: 0;
            margin-top: -1px;
            min-width: 100%;
            text-align: center;
            border: none;
            font-size: 1.1rem;

            @include media-breakpoint-up(lg)
            {
                animation-name: slideIn;
                animation-duration: 0.3s;
                animation-fill-mode: both;
            }

            .dropdown-item
            {
                color: white !important;
                transition: background-color 0.2s;

                &:hover
                {
                    background-color: lighten($dark, 15%);
                }
            }
        }
    }

    .navbar-toggler
    {
        background-color: $dark;
        color: white;
        line-height: 1;
        padding: .5rem .75rem;
        right: 0;
        top: 0;
        z-index: 2;
        position: absolute;
        font-size: 1.8rem;
        border: none;

        @include media-breakpoint-up(lg)
        {
            display: none;
        }

        &:focus
        {
            outline: none;
            box-shadow: none;
            border: none;
        }
    }
}

.has-smart-header {
    #main-header:not(.scrolled)
    {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin-top: 20px;
        color: white;
        background: none;
        transition: margin-top 0.3s, background-color 0.3s, color 0.3s;
        box-shadow: 0 5px 6px 0px rgba(79, 79, 79, 0.62);
        

        .navbar
        {
            background: #0000001f;
            // blur background
            backdrop-filter: blur(4px);
        }

        @include media-breakpoint-up(lg)
        {
            border: solid 1px white;
            border-left: none;
            border-right: none;
        }

        a{
            color: white;
            // text shadow
            text-shadow: 0px 0px 8px rgba(0,0,0,0.9);
        }
    }
}