@import './cookie-consent.scss';

:root {
    --ss-bg-color: var(--bs-body-bg);
    --ss-font-color: var(--bs-body-color);
    --ss-border-color: var(--bs-border-color);
    --ss-primary-color: var(--bs-primary);
    --ss-border-radius: var(--bs-border-radius);
}

.vc-light {
    --vc-bg: var(--bs-body-bg);
    --vc-header-arrow-hover-bg: var(--bs-body-bg);
    --vc-color: var(--bs-body-color);
    --vc-header-title-color: var(--bs-body-color);
    --vc-header-arrow-color: var(--bs-body-color);
    --vc-focus-ring: var(--bs-primary);
    --vc-border: var(--bs-border-color);
    --vc-popover-content-border: var(--bs-border-color);
    --vc-focus-ring: var(--bs-primary);

    .vc-header {
        button {
            background-color: transparent;
        }
    }
}

[v-cloak] {
    display: none;
}

.ss-main .ss-multi-selected .ss-add {
    margin: 13px 13px 0 5px;
}

.ss-content .ss-list .ss-option.ss-highlighted,
.ss-content .ss-list .ss-option:not(.ss-disabled).ss-selected,
.ss-content .ss-list .ss-option:hover {
    color: var(--bs-light);
}

.table-admin {
    table {
        th {
            border-bottom-width: 2px;
            border-top-width: 2px;

            .sortable-col {
                cursor: pointer;
            }
        }
    }
}


.tox {
    background-color: var(--bs-form-control-bg);
    border-color: var(--bs-card-border-color);

    .tox-toolbar-overlord,
    .tox-menubar {
        background-color: var(--bs-card-bg);
        border-color: transparent;
        color: var(--bs-body-color);
    }

    .tox-edit-area__iframe {
        background-color: var(--bs-form-control-bg);
        color: var(--bs-body-color);
    }

    .tox-toolbar__primary {
        background-color: var(--bs-card-cap-bg) !important;
        border-color: var(--bs-card-border-color) !important;
    }

    .tox-toolbar__group {
        border-color: var(--bs-card-border-color) !important;
    }

    &:not(.tox-tinymce-inline) {
        .tox-editor-header {
            background-color: var(--bs-card-bg);
            border-color: var(--bs-card-border-color);
        }
    }

    .tox-statusbar {
        background-color: var(--bs-card-cap-bg);
        color: var(--bs-body-color);
        border-color: var(--bs-card-border-color);
    }

    .tox-statusbar__path-item {
        color: var(--bs-body-color);
    }

    .tox-tbtn,
    .tox-tbtn:active,
    .tox-tbtn--disabled,
    .tox-mbtn {
        background-color: var(--bs-card-cap-bg);
        color: var(--bs-body-color);

        svg {
            fill: var(--bs-body-color);
        }
    }
}

cookie-consent-banner {
    visibility: hidden;
}

.dateselect {
    .ss-main {
        min-width: auto;
        width: 33%;
    }
}