ul.list-tree {
    list-style: none;
    padding-left: 18px;
    position: relative;

    li {
        position: relative;
        background-color: transparent;
        overflow-y: auto;

        span {
            &:hover {
                cursor: pointer;
                color: var(--bs-body-color);
            }
        }

    }

    li>ul {
        padding-left: 18px;
        border-left: solid 1px $border-color;
        position: relative;

        li {
            position: relative;

            &:before {
                display: inline-block;
                position: absolute;
                content: " ";
                left: -16px;
                top: 11px;
                width: 12px;
                height: 18px;
                border-top: solid 1px $border-color;
            }
        }
    }
}