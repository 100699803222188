
/* =====================
 FOOTER
========================*/
#main-footer
{
    background: linear-gradient(198deg, $primary 0%, darken($primary, 10%) 62%, darken($primary, 20%) 100%);
    border-top: solid 4px var(--bs-primary);
    color: #fffdfd;
    padding: 30px 0;
    font-size: 1.1rem;

    a
    {
        color: darken(#ffffff, 15%);

        &:hover
        {
            color: #fffdfd;
        }
    }

    .ecusson
    {
        display: flex;
        align-items: flex-end;

        span
        {
            display: inline-block;
            margin-left: 10px;
        }
    }

    .nav-item
    {
        padding: 2px 4px;
    }

    .copyright
    {
        font-size: 0.9rem;
        margin-top: 10px;
        padding-top: 10px;
        border-top: #ffffffa1 solid 1px;
        display: flex;
        justify-content: flex-end;
        opacity: 0.8;

        .oviglo {
            img {
                height: 28px;
                margin-left: 6px;
            }
        }
    }
}